import React , {Component} from 'react';
import Carousel from './carousel';
import image0 from '../assets/images/image0.png';
import image2 from '../assets/images/majorelle.jpeg';
import Orthèses from '../assets/images/Orthotics/Orthotics.jpeg';
import corset from '../assets/images/Corsets/corset.jpeg';
import semelles from '../assets/images/Semelles/semelles.jpeg';
import prothèses from '../assets/images/Prothèses/prothèses.jpeg';
import positionnement from '../assets/images/Positionnement/positionnement.jpeg';


class Home extends Component {
    constructor(props) {
      super(props);
      this.state = {
       
      };
    }
  


  
    render() {
     
        
    return  (
        <>
        <Carousel />
        <br />

        {/* Content1  */}

        <div className="container" style={{marginTop: '3%'}}>
        <div className="row">
            <div className="col-md-12 col-lg-6">
                <div className="container"><img src={image0} style={{ width: '100%'}} /></div>
            </div>
            <div className="col-md-12 col-lg-6">
                <div className="container" style={{marginTop: '7%'}}>
                    <div className="base_header"><span><small className="bor_header_left"></small>Réintégrer la vie active<small className="bor_header_right"></small></span>
                        <h4>Nous sommes spécialisés dans la conception et la fabrication de différents types d'orthèses, de corsets, de semelles orthopédiques, de prothèses et de dispositifs de positionnement sur mesure.</h4>
                    </div>
                    <div className="base_footer">
                        <p>Majorelle orthopédie est dirigée par une équipe professionnelles expérimentée et qualifiée dans l’appareillage orthopédique sur mesure, elle se consacrent à fournir un appareillage de qualité supérieure à tous ses patients. <br />Nous utilisons les dernières techniques et technologies pour créer des dispositifs qui répondent aux besoins spécifiques de chaque patient, afin de les aider à atteindre leur plein potentiel de mouvement et de fonctionnement.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <br />

        {/* Content2  */}

        <div  className="steps">
        <div className="base_header text-center" style={{paddingTop: '2%'}}><span><small className="bor_header_left"></small>Réintégrer la vie active<small className="bor_header_right"></small></span>
            <h3>Nos solutions orthopédiques</h3>
        </div>
        <div className="container" style={{ marginTop: "3%",paddingBottom: '3%' }} >
            <div className="row">
                <div className="col-md-4 text-center steps">
                    <div><img src={Orthèses} alt="step1" style={{ width: '100%', height: '500px'}} />
                        <h4><strong>Orthèses</strong></h4>
                        <h4>Les orthèses orthopédiques sont des appareils sur mesure qui suppléent à des fonctions motrices défaillantes au niveau des membres et du rachis.</h4>
                    </div>
                </div>
                <div className="col-md-4 text-center steps">
                    <div><img src={corset} alt="step1" style={{ width: '100%' , height: '500px'}} />
                        <h4><strong>Corsets</strong></h4>
                        <h4>Les corsets sont des dispositifs médicaux orthopédiques sur mesure destinés à corriger ou à immobiliser tout ou une partie du rachis.</h4>
                    </div>
                </div>
                <div className="col-md-4 text-center steps">
                    <div><img src={semelles} alt="step2" style={{ width: '100%', height: '500px'}} />
                        <h4><strong>Semelles</strong></h4>
                        <h4>Les semelles orthopédiques sur mesure sont destinées à prévenir, traiter, corriger des problématiques fonctionnelles biomécaniques, posturales ou un handicap au niveau des pieds.</h4>
                    </div>
                </div>
                <div className="col-md-4 text-center steps">
                    <div><img src={prothèses} alt="step2" style={{ width: '100%', height: '500px'}} />
                        <h4><strong>Prothèses</strong></h4>
                        <h4>La prothèse vient remplacer un membre entier manquant ou une partie de membre (jambe, pied, avant-bras, main ….) qui a été amputé.</h4>
                    </div>
                </div>
                <div className="col-md-4 text-center steps">
                    <div><img src={positionnement} alt="step3" style={{ width: '100%', height: '500px'}} />
                        <h4><strong>Orthèses de positionnement</strong></h4>
                        <h4>Sont des orthèses orthopédiques sur mesure confectionnées pour maintenir le patient dans une position donnée, debout grâce à un verticalisateur, assise par le biais d’un corset-siège et couchée à l’aide d’un matelas en mousse.  </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

      

    {/* Content4  */}

    <div className="container" style={{marginTop: '3%'}}>
        <div className="row">            
            <div className="col-md-12 col-lg-6">
                
                    <div className="container">
                    <div className="base_header"><span><small className="bor_header_left"></small>Réintégrer la vie active<small className="bor_header_right"></small></span>
                        <h3>Majorelle Orthopedie</h3>
                    </div>
                    
                    <div className="base_footer" key="1">
                        <p>
                        Notre cabinet est équipé d'installations modernes et d'équipements de pointe pour fabriquer des dispositifs sur mesure, adaptés aux besoins de chaque patient. Nous travaillons en étroite collaboration avec les médecins et les autres professionnels de la santé pour élaborer un plan de traitement personnalisé pour chaque patient.
                        </p>                       
                    </div>  
                    <div className="base_footer" key="2">
                        <p>
                        Nous sommes heureux de vous accueillir sur notre site web et nous espérons que vous trouverez les informations que vous recherchez. Si vous avez des questions ou souhaitez prendre rendez-vous, n'hésitez pas à nous contacter. Nous sommes là pour vous aider à améliorer votre qualité de vie!
          </p>                       
                    </div>  
                </div>
                
                
            </div>
            <div className="col-md-12 col-lg-6">
                <div className="container" ><img src={image2} style={{ width: '100%'}} /></div>
            </div>
        </div>
    </div>

    <br />

   

       
        </>
    );
}
}
export default Home;