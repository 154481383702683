import "./components/assets/css/index.css";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/footer";
import Routes from "./components/routes";
import logo from "./components/assets/images/Majorelle-orthopedie.png";
import { FiChevronsDown } from "react-icons/fi";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';


const Item = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.h6,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function App() {
  return (
    <>
      <BrowserRouter>
        {/* NAVBAR */}
        <nav className="navbar navbar-light navbar-expand-md navigation-home">
          <div className="container">
            <a href="/">
            <Stack direction="row" spacing={2}>
        <Item><img src={logo} alt="logo_homie" style={{ height: "65px" }} /></Item>
        <Item style={{marginTop:'12' }} >
              Majorelle Orthopedie
      </Item>
       
      </Stack>
              
              
              
            </a>
            <button
              data-bs-toggle="collapse"
              className="navbar-toggler"
              data-bs-target="#navcol-1"
            >
              <span className="visually-hidden">Toggle navigation</span>
              <span className="">
                <FiChevronsDown />
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navcol-1">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a className="nav-link home-link" href="/">
                  Accueil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link home-link" href="/about">
                  À Propos
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link home-link" href="/service">
                  Services
                  </a>
                </li>
                
                <li className="nav-item">
                  <a className="nav-link home-link" href="/contact">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* END OF NAVBAR */}

        <Routes />

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
