import React , {Component} from 'react'; 

class TermsPrivacy extends Component {

    constructor(props) { 
        super(props);
        this.state = {
            terms: {},
            privacy: {},
        };
    }


    render() { 
        const { terms , privacy } = this.state;
        return (
            <>
            <div class="container" style={{marginTop: '3%'}}>
            <div class="row">            
                <div class="col-md-12 col-lg-10">
                    <div class="container">
                        <div class="base_header"><span><small class="bor_header_left"></small>Termes et Conditions<small class="bor_header_right"></small></span>
                            <h3>Termes et Conditions de Majorelle Orthopédie</h3>
                        </div>
                        <div class="base_footer">
                            
                        <h3>1. Introduction</h3>
                        <p>Bienvenue sur le site web de Majorelle Orthopédie, un site spécialisé dans la conception et la fabrication de différents types d'orthèses, de corsets, de semelles orthopédiques, de prothèses et de dispositifs de positionnement. Ce site est géré par Majorelle Orthopédie et est soumis aux lois marocaines. En utilisant notre site, vous acceptez de respecter les termes et conditions décrits ci-dessous. Si vous n'êtes pas d'accord avec ces termes et conditions, veuillez ne pas utiliser notre site.</p> 
                        <h3>2. Utilisation du site</h3>
                        <p>Vous pouvez utiliser notre site pour naviguer, rechercher des informations et acheter nos produits. Toutefois, vous ne devez pas utiliser notre site à des fins illégales ou nuisibles, ou pour tout autre usage qui pourrait endommager, désactiver, surcharger ou nuire à notre site ou à notre réseau. Vous ne devez pas non plus utiliser notre site pour envoyer des courriels non sollicités ou pour collecter des informations personnelles sur les autres utilisateurs.</p> 
                        <h3>3. Propriété intellectuelle</h3>
                        <p>Tout le contenu présent sur notre site, y compris les textes, images, logos, graphismes, et codes HTML, est protégé par les lois sur la propriété intellectuelle. Vous n'êtes pas autorisé à utiliser, reproduire, distribuer ou afficher ce contenu sans notre autorisation écrite.

                            </p> 
                            <p>Des images utilisées sur notre site sont en provenance du site "https://www.pexels.com/". Et sont utilisées en accord avec les termes d'utilisation de ce site.</p>
                        <h3>4. Responsabilité</h3>
                        <p>Nous faisons de notre mieux pour maintenir la précision et l'actualité des informations présentes sur notre site. Cependant, nous ne pouvons pas garantir l'exactitude, l'intégralité ou la pertinence de ces informations. Nous ne sommes pas responsables des erreurs ou des inexactitudes qui peuvent se produire sur notre site.</p> 
                        <h3>5. Liens vers d'autres sites</h3>
                        <p>Notre site peut contenir des liens vers d'autres sites qui ne sont pas gérés par nous. Nous ne sommes pas responsables du contenu de ces sites et n'acceptons aucune responsabilité pour les dommages ou pertes qui peuvent résulter de l'utilisation de ces sites.</p> 
                        <h3>6. Modifications des termes et conditions</h3>
                        <p>Majorelle Orthopedie se réserve le droit de modifier les présentes Conditions à tout moment et sans préavis. Les Conditions modifiées entreront en vigueur immédiatement après leur publication sur le Site</p> 
                       
                        </div>
    
                        < br />
                        < br />
    
                        <div class="base_header"><span><small class="bor_header_left"></small>POLITIQUE DE CONFIDENTIALITÉ<small class="bor_header_right"></small></span>                    
                            <h3>POLITIQUE DE CONFIDENTIALITÉ</h3>
                        </div>
                        <div class="base_footer">
                        <p>La présente politique de confidentialité (ci-après la "Politique") décrit les types de données à caractère personnel que "Majorelle Orthopedie" (ci-après "Majorelle Orthopedie" ou "nous") recueille et comment nous utilisons, protégeons et partageons ces données. Elle s'applique à toutes les données à caractère personnel que nous recueillons sur le site internet "Majorelle Orthopedie" (ci-après le "Site").</p>                            
                        <p>En accédant au Site et en utilisant les services proposés par Majorelle Orthopedie, vous acceptez la collecte, l'utilisation et le partage de vos données à caractère personnel conformément à la présente Politique. Si vous n'acceptez pas la présente Politique, vous ne devez pas utiliser le Site et les services proposés par Majorelle Orthopedie.</p> 
                        <h3>1. Types de données à caractère personnel que nous recueillons</h3>  
                        <p>Nous pouvons recueillir les types de données à caractère personnel suivants :</p> 
                        <ul>
                            <li>Les informations que vous nous fournissez lorsque vous remplissez des formulaires sur le Site, comme votre nom, votre adresse e-mail, votre numéro de téléphone, etc.</li>
                            <li>Les informations de navigation que nous collectons automatiquement lorsque vous utilisez le Site, comme votre adresse IP, le type de navigateur que vous utilisez, les pages que vous consultez, etc.</li>
                            <li>Les informations que vous nous fournissez lorsque vous nous contactez par e-mail ou par téléphone.</li>
                            </ul>    
                            <h3>2. Utilisation de vos données à caractère personnel</h3>  
                        <p>Nous utilisons vos données à caractère personnel pour les finalités suivantes :</p> 
                        <ul>
                            <li>Pour vous fournir les informations et les services que vous demandez, comme la réponse à vos questions ou la confirmation de votre commande.</li>
                            <li>Pour améliorer le Site et les services que nous proposons, en analysant les données de navigation et les commentaires que nous recevons.</li>
                            <li>Pour vous envoyer des informations sur les produits et les services de Majorelle Orthopedie, si vous avez accepté de recevoir des communications de notre part.</li>
                            </ul>  
                            <h3>3. Protection de vos données à caractère personnel</h3>  
                            <p>Nous prenons les mesures de sécurité appropriées pour protéger vos données à caractère personnel contre la perte, l'utilisation abusive, l'accès non autorisé, la divulgation et la modification.

                                </p>  
                                <h3>4. Partage de vos données à caractère personnel</h3>  
                                <p>Nous ne partageons pas vos données à caractère personnel avec des tiers</p>                
                        </div>
    
                        <br />  
                    
    
                    </div>
                </div>
            </div>
        </div> 
            </>
        );
    }
}
 
export default TermsPrivacy;
 