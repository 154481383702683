import React, { Component } from "react";
import { motion } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import { container, item } from "./framerMotion";
import { ProdactCard } from "./ProdactCard";
import Chip from '@mui/material/Chip';
import Orthotics1 from './assets/images/Orthotics/Orthotics.jpeg';
import Orthotics2 from './assets/images/Orthotics/12-Orthèses.jpeg';
import Orthotics3 from './assets/images/Orthotics/2487-Orthèses.jpeg';
import Orthotics4 from './assets/images/Orthotics/Orthèses-091.jpeg';
import Corsets1 from './assets/images/Corsets/corset.jpeg';
import Corsets2 from './assets/images/Corsets/Corsets-091.jpeg';
import Corsets3 from './assets/images/Corsets/Corsets-092.jpeg';
import Corsets4 from './assets/images/Corsets/Corsets-093.jpeg';
import Corsets5 from './assets/images/Corsets/Corsets-094.jpeg';
import Semelles1 from './assets/images/Semelles/semelles.jpeg';
import Prothèses1 from './assets/images/Prothèses/3-Prothèses.jpeg';
import Prothèses2 from './assets/images/Prothèses/43-Prothèses.jpeg';
import Prothèses3 from './assets/images/Prothèses/45789-Prothèses.jpeg';
import Prothèses4 from './assets/images/Prothèses/87675-Prothèses.jpeg';
import Prothèses5 from './assets/images/Prothèses/ad-Prothèses.jpeg';
import Prothèses6 from './assets/images/Prothèses/emk-Prothèses.jpeg';
import Prothèses7 from './assets/images/Prothèses/fo-Prothèses.jpeg';
import Prothèses8 from './assets/images/Prothèses/l-Prothèses.jpeg';
import Prothèses9 from './assets/images/Prothèses/prothèses.jpeg';
import Prothèses10 from './assets/images/Prothèses/Prothèses-091.jpeg';
import Prothèses11 from './assets/images/Prothèses/Prothèses-092.jpeg';
import Prothèses12 from './assets/images/Prothèses/Prothèses-093.jpeg';
import Prothèses13 from './assets/images/Prothèses/Prothèses-094.jpeg';
import Prothèses14 from './assets/images/Prothèses/Prothèses-095.jpeg';
import Prothèses15 from './assets/images/Prothèses/Prothèses-096.jpeg';
import Prothèses16 from './assets/images/Prothèses/Prothèses-097.jpeg';
import Positionnement from './assets/images/Positionnement/positionnement.jpeg';










class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      prodacts : [],
      sel : 'TOUS'
    };
  }

  componentDidMount() {
     this.setState({ 
      services: [{
        'name':'TOUS','active':true},
        {'name':'ORTHÈSES','active':false},
        {'name':'CORSETS','active':false} ,
         {'name':'SEMELLES','active':false},
         {'name':'PROTHÈSES','active':false} ,
         {'name':'POSITIONNEMENT','active':false} 
        ] })

        this.setState({ 
          prodacts: [
            {'img':Prothèses1,'name':'Prothèses','type':'PROTHÈSES'}, 
            {'img':Corsets2,'name':'Corsets','type':'CORSETS'},
            {'img':Prothèses10,'name':'Prothèses','type':'PROTHÈSES'},
            {'img':Orthotics1,'name':'Orthotics','type':'ORTHÈSES'},
            {'img':Prothèses5,'name':'Prothèses','type':'PROTHÈSES'},
            {'img':Prothèses2,'name':'Prothèses','type':'PROTHÈSES'}, 
            {'img':Corsets1,'name':'Corsets','type':'CORSETS'},
            {'img':Orthotics3,'name':'Orthotics','type':'ORTHÈSES'} ,
            {'img':Prothèses4,'name':'Prothèses','type':'PROTHÈSES'},
            {'img':Prothèses12,'name':'Prothèses','type':'PROTHÈSES'}, 
            {'img':Prothèses9,'name':'Prothèses','type':'PROTHÈSES'}, 
             {'img':Semelles1,'name':'Semelles','type':'SEMELLES'} ,
             {'img':Corsets3,'name':'Corsets','type':'CORSETS'},
             {'img':Prothèses13,'name':'Prothèses','type':'PROTHÈSES'},
             {'img':Orthotics4,'name':'Orthotics','type':'ORTHÈSES'} ,
             {'img':Orthotics2,'name':'Orthotics','type':'ORTHÈSES'},
             {'img':Positionnement,'name':'Verticalisateur','type':'POSITIONNEMENT'}, 
             {'img':Prothèses14,'name':'Prothèses','type':'PROTHÈSES'},
             {'img':Prothèses3,'name':'Prothèses','type':'PROTHÈSES'}, 
             {'img':Prothèses11,'name':'Prothèses','type':'PROTHÈSES'},
             {'img':Prothèses15,'name':'Prothèses','type':'PROTHÈSES'},
             {'img':Corsets4,'name':'Corsets','type':'CORSETS'}, 
             {'img':Prothèses6,'name':'Prothèses','type':'PROTHÈSES'}, 
             {'img':Prothèses7,'name':'Prothèses','type':'PROTHÈSES'},
             {'img':Prothèses16,'name':'Prothèses','type':'PROTHÈSES'}, 
             {'img':Prothèses8,'name':'Prothèses','type':'PROTHÈSES'}, 
             {'img':Corsets5,'name':'Corsets','type':'CORSETS'},
            ] })

  }

  render() {
    const { services } = this.state;
    const { prodacts } = this.state;
    const { sel } = this.state;
    

 const handleGenres = (id) => {

  const nextCounters = services.map((c) => {
    if (c.name === id) {
      // Increment the clicked counter
      return {'name':c.name,'active':true};
    } else {
      // The rest haven't changed
      return {'name':c.name,'active':false};
    }
  });
  
  this.setState({
     services : nextCounters,
     sel:id
   })
   
 
 };

    return (
      <>
        <div className="container" style={{ marginTop: "3%" }}>
          <div className="base_header">
            <span>
              <small className="bor_header_left"></small>SERVICES
              <small className="bor_header_right"></small>
            </span>
            <Container className="mt-4">
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
          className="genres d-flex flex-wrap "
          style={{
            gap:'5px 15px'
          }}
        >
          {services.map((item) => (
            
<motion.h3
 whileTap={{
  scale: 0.8,
}}
key={item.name}
onClick={()=>handleGenres(item.name)}  >

  
  <Chip label={item.name}  color={item.active?"error":"default"} />
</motion.h3>

          ))}
        </motion.div>
        <div className="wrapper mt-4">
          <Row md={3} xs={1} lg={3} className="g-4">

            { (sel === 'TOUS')?
            prodacts.map((item) => (
              <Col key={item.img}>
                <ProdactCard  prodact={item} />
              </Col>
            
            ))
            :
          
            prodacts.map((item) => (
              (item.type === sel)?
              <Col key={item.img}>
                <ProdactCard  prodact={item} />
              </Col>
             :
             <React.Fragment key={item.img}>
               </React.Fragment>
            ))
               
          }
          </Row>
        </div>
       
      </Container>

          </div>
        </div> 
        <br /> <br />
      </>
    );
  }
}

export default Service;
