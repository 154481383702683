import {FiFacebook , FiTwitter ,FiLinkedin ,FiInstagram , FiPhone} from 'react-icons/fi'; 
import {IoMailOutline } from 'react-icons/io5';

const Footer = () => { 
    return ( 
        <>
        <footer className="footer-dark">
        <div className="container">
            <div className="row">
                <div className="col-sm-6 col-md-4 item">
                    <h3 className="footer-links-title">Liens utiles</h3>
                    <ul className="footer-links">
                        <li><a href="/about">Qu'est-ce que Majorelle Orthopedie</a></li>
                        <li><a href="/contact">Contact</a></li>
                        <li><a href="/terms-privacy">termes et conditions</a></li>
                        <li><a href="/terms-privacy">Politique de confidentialité</a></li>
                    </ul>
                </div>
                <div className="col-sm-6 col-md-4 item">
                    <h3><strong>Villes</strong></h3>
                    <ul className="footer-links">
                        <li><a>Marrakech</a></li>
                    </ul>
                </div>
                
               
                <div className="col-sm-6 col-md-4 item">
                    <h3><strong>Connecte-toi avec nous</strong></h3>
                    <ul className="footer-links">
                        <li><a href="tel:+212524434963"><FiPhone style={{fontSize:'25px' , paddingRight: '1%'}}/>+212 5244-34963</a></li>
                        <li><a href="tel:+212667480740"><FiPhone style={{fontSize:'25px' , paddingRight: '1%'}}/>+212 6674-80740</a></li>
                        <li><a href = "mailto:info@majorelle-orthopedie.com" ><IoMailOutline style={{fontSize:'25px' , paddingRight: '1%'}}/>info@majorelle-orthopedie.com</a></li>
                        <br />
                        <li className="footer-icons"><a href="#"><FiLinkedin /></a><a href="#"><FiFacebook /></a><a href="#"><FiTwitter /></a><a href="#"><FiInstagram /></a></li>
                        <br />
                        
                        
                    </ul>
                </div>
            </div>
            <p className="copyright">Majorelle Orthopedie © 2023</p>
        </div>
    </footer>
        </>
    )
}
export default Footer; 