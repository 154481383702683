import React from 'react';

const about = () => {
    return (
        <>
        <div className="container" style={{marginTop: '3%'}}>
        <div className="row">            
            <div className="col-md-12 col-lg-10">
                <div className="container">
                    <div className="base_header"><span><small className="bor_header_left"></small>À PROPOS de Majorelle Orthopedie<small className="bor_header_right"></small></span>
                        <h3>Qu'est-ce que Majorelle Orthopedie</h3>
                    </div>
                    <div className="base_footer">
                        <p>Majorelle Orthopédie est spécialisée dans la conception et la fabrication de tous types d’orthèses et de prothèses des membres inférieurs et supérieurs sur mesure et aussi dans la vente du petit appareillage (appareillage de série)..</p>
                    </div>

                    < br />

                    <div className="base_header">
                        <h3>Pourquoi Majorelle Orthopedie</h3>
                    </div>
                    <div className="base_footer">
                        <p>Chez Majorelle Orthopédie, nous sommes fiers de notre expertise dans la conception et la fabrication de tous types d'orthèses et de prothèses pour les membres supérieurs et inférieurs. Nous sommes également spécialisés dans la vente de petits appareils orthopédiques de série pour répondre aux besoins de tous nos patients.</p>
                    </div>

                    <br />  
                    <div className="base_header">
                        <h3>Notre vision</h3>
                    </div>
                    <div className="base_footer">
                        <p> Notre processus d’appareillage orthopédique est basé sur l'analyse, la proposition et l'adaptation pour répondre précisément aux besoins et aux attentes de nos patients. Nous offrons une écoute active et un bilan podologique avec un plateau d'examen performant pour que nos patients puissent bénéficier d’une prise en personnalisée et globale.</p>
                    </div>
                    <div className="base_footer">
                        <p>En utilisant les dernières technologies et techniques, nous conseillons, concevons et fabriquons des solutions orthopédiques sur-mesure, confortables et harmonieuses dans nos ateliers. Pour garantir une prise en charge de qualité, nous utilisons des dispositifs et des matériaux du premier choix dans la fabrication des appareils orthopédiques personnalisés.
           </p>
                    </div>
                    <div className="base_footer">
                        <p>Notre motivation principale est de trouver une solution personnalisée qui répond aux problèmes orthopédiques de nos patients tout en prenant en compte leur confort et leur esthétique. Nous travaillons en étroite collaboration avec les autres acteurs de la santé pour prévenir et répondre aux désordres fonctionnels et aux problèmes associés tels que les déformations, les rétractations, la perte de la motricité et le handicap.
 </p>
                    </div>
                   
                    <div className="base_footer">
                        <p>  Nous sommes engagés à fournir des soins de qualité supérieure à tous nos patients et nous sommes heureux de les accompagner pour réintégrer la vie active.
</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
        </>
    )
}

export default about;
