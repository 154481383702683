import React from 'react'
import error from './assets/images/error.webp';
const Pagenotfound = () => {
    return (
        <div style={{textAlign:'center'}}>
            <img style={{width:'55%' }} src={error} alt="404"/>
        </div>
    )
}

export default Pagenotfound
