import React from 'react';
import { FiPhone, FiMail } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";

const contact_component = () => {
    return (
        <>
        <div className="container" style={{ marginTop: "3%" }}>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="container">
              <div className="base_header">
                <span>
                  <small className="bor_header_left"></small>CONTACT
                  <small className="bor_header_right"></small>
                </span>
                <h3>Vous avez des questions ?</h3>
              </div>
              <div className="base_footer">
                <br />
                <ul style={{ listStyle: "none" }}>
                   <a href="tel:+212524434963">
                  <li className="contact-icons">
                   
                    <FiPhone />
                    <label className="form-label">+212 5244-34963</label>
                    
                  </li></a>
                  <a href="tel:+212667480740">
                  <li className="contact-icons">
                    <FiPhone />
                    <label className="form-label">+212 6674-80740</label>
                  </li>
                  </a>
                  <a href = "mailto:info@majorelle-orthopedie.com">
                  <li className="contact-icons">
                    <FiMail />
                    <label className="form-label">info@majorelle-orthopedie.com</label>
                  </li>
                  </a>
                  <li className="contact-icons">
                    <IoLocationOutline /><br/>
                    <label className="form-label">2, rue du Sergent Levet,</label><br/>
                    <label className="form-label">Guéliz</label><br/>
                    <label className="form-label">Marrakech 40000</label><br/>
                  </li>
                </ul>
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3396.8192834700835!2d-8.02314348488977!3d31.63879804845041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdafefe9215fd51b%3A0xa64be7944ab6f475!2sMAJORELLE%20ORTHOP%C3%89DIE!5e0!3m2!1sen!2sma!4v1674328383296!5m2!1sen!2sma"
                style={{ border: 0, width: "100%", height: "55vh" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <br />
      </>
    )
}

export default contact_component
