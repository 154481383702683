import React from "react";
import { Card } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

//import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";


export const ProdactCard = ({ prodact }) => {
  //const navigate = useNavigate();

  return (
  <motion.div
  initial={{ scale: 0,opacity:0 }}
  animate={{ opacity:1, scale: 1 }}
  transition={{
    type: "spring",
    stiffness: 260,
    damping: 40,
  }}
  
  >
      <Card
      key={prodact.img}
      style={{
        width: "100%",
        background: "#161616",
        color: "white",
        borderRadius: 6,
        position: "relative",
      }}
      className=" movie-card"
    >
      <Card.Body>
        <LazyLoadImage
          src={prodact.img}
          width={"100%"}
          height={350}
          alt={prodact.name}
          effect="blur"
          style={{ objectFit: "cover" }}
        />
        <Card.Title
         // onClick={() => navigate(`/${movie.id}`)}
          className="text-center mt-3"
          style={{ cursor: "pointer" }}
        >
          {prodact.name}
        </Card.Title>
      </Card.Body>
    </Card>
  </motion.div>
  );
};
