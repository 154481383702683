import React from "react";
import Contact_component from "./contact_component";
import axios from "axios";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

class Contact_us extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      formDisplay:"block",
      messageDisplay:"none"
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = async(e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://us-central1-artismak.cloudfunctions.net/mailSend', {
        from: this.state.name,
        email: this.state.email,
      mobile: this.state.phone,
      subject:"Test func",
      site:"Majorelle",
      text: this.state.message,
      });
      this.setState({
        name: "",
        email:"",
        phone: "",
        message: "",
        formDisplay:"none",
      messageDisplay:"block"
      })
    } catch (error) {
      console.log('error',error);
    }
   
  
      
  };

  render() {
    const { name,email, phone, message,messageDisplay,formDisplay  } = this.state;
    return (
      <>
        <Contact_component />

        <div className="container">
          <div
            className="container"
            style={{
              border: "0.3px solid #C4C4C4",
              padding: "3%",
              borderRadius: "5px",
            }}
          >
             <Stack sx={{ width: '100%', display:messageDisplay }} spacing={2}>
           <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        <strong> Merci!</strong> Nous avons bien reçu votre demande et vous contacterons dans les plus brefs délais.
      </Alert>
            </Stack>
            <form className="contact-form container" onSubmit={this.submitHandler} style ={{display:formDisplay}}>
              <h1>ENVOYEZ-NOUS UN MESSAGE</h1>
              <div className="container" style={{ marginTop: "3%" }}>
                <h4>Nom et Prénom</h4>
                <input
                  className="form-control"
                  onChange={this.changeHandler}
                  value={name}
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Entrez votre nom"
                  required
                />
              </div>
              <div className="container" style={{ marginTop: "3%" }}>
                <h4>Email</h4>
                <input
                  className="form-control"
                  onChange={this.changeHandler}
                  value={email}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Entrez votre Email"
                  required
                />
              </div>
              <div className="container" style={{ marginTop: "3%" }}>
                <h4>Numéro de portable</h4>
                <input
                  className="form-control"
                  onChange={this.changeHandler}
                  value={phone}
                  id="phone"
                  name="phone"
                  type="number"
                  placeholder="Entrez votre Numéro de portable"
                  required
                />
              </div>
              <div className="container" style={{ marginTop: "3%" }}>
                <h4>Votre demande</h4>
                <textarea
                  className="form-control"
                  placeholder="Votre message va ici"
                  onChange={this.changeHandler}
                  value={message}
                  id="message"
                  name="message"
                  required
                ></textarea>
              </div>
              <div className="container mb-3" style={{ marginTop: "3%" }}>
                <button
                  className="btn btn-primary d-block w-25"
                  style={{ background: "#006fb9", border: "none" }}
                  type="submit"
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>

        <br />
        <br />
      </>
    );
  }
}

export default Contact_us;
