import React from 'react';
import Carousel from "framer-motion-carousel";
import CarouselImg from "../assets/images/carousel1.jpeg"
import CarouselImg1 from "../assets/images/carousel2.jpeg"



class Banner extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  render() {  

    const image = [CarouselImg ,CarouselImg1]
    return (
      <>
  <Carousel interval={5000}>
    {image.map((item, i) => (
      <img
        draggable="false"
        src={`${item}`}
        key={i}
        width="100%"
        height="520"
        alt=""
      />
    ))}
  </Carousel>

</>
  );
  }
}
 
export default Banner;
